/* General Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #333;
    background-color: #F2F2F2;
    transition: background 0.3s, color 0.3s;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ececec' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23e6e6e6' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23e0e0e0' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23dadada' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23D4D4D4' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23cfcfcf' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23cacaca' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23c4c4c4' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23bfbfbf' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23BABABA' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
}

body.night {
    background-color: #22303E !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-position: center center !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23243342' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23263646' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23293949' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%232b3c4d' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%232D3F51' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23304356' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2333475b' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23354a60' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23384e65' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%233B526A' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E") !important;
}

body ::selection {
    background: #54B949;
    color: #f4f4f9;
}

/* Highlighting in Night Theme */
body.night ::selection {
    background: #f1c40f !important;
    color: #000 !important; /* Optional: Set text color to black for better contrast */
}

@media (max-width: 767px) {
    body {
        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */

    }

    body.night {
        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */
    }
}

#portfolio, #resume, #contact #experience {
    padding: 3em 1em;
    text-align: center;
}

h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    letter-spacing: 2px;
    color: #444;
    text-transform: uppercase;
    padding-bottom: 10px;
    text-align: center;
    align-items: center;

    font-weight: 500;
}

body.night h2 {
    color: #e8e8e8 !important;
}

h2::after {
    content: '';
    width: 100px;
    height: 4px;
    background: #53B944;
    display: block;
    margin: 0.5em auto;
    border-radius: 2px;
}

body.night h2::after {
    background: #f1c40f !important;
}


/* First section */
/* Hero section */
#hero {
    overflow: hidden;
    transition: background 0.5s ease-in-out;
    background-color: #35762E;
    background-size: cover;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23387c30' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%233a8133' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%233d8735' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%233f8c38' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%2342923A' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23459a3d' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2349a240' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%234caa42' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2350b245' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2354B949' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;  
    background-repeat: no-repeat; /* Ensuring the background doesn't repeat */

    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the main content vertically */
    align-items: center; /* Centers the main content horizontally */
    height: 100vh;
    padding-bottom: 2em; /* Adds some space at the bottom for the social links */
}

body.night #hero {
    background-color: #2C3E50 !important;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;  
    transition: background 0.5s ease-in-out; /* Smoother transition */
    background-color: #2C3E50 !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%232e4154' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23304458' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2333485c' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23354b60' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23374E64' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%233a5269' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%233d566f' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23405b74' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2346637F' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2346637F' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat; /* Ensuring the background doesn't repeat */
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#hero::after {
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

@keyframes fadeInOnce {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#dynamic-text {
    color: #f6f6f6;
}

#social-links {
    position: absolute; /* Allows positioning relative to the bottom of the hero section */
    bottom: 2em; /* Positions social links slightly above the bottom */
    left: 50%; /* Centers the social links horizontally */
    transform: translateX(-50%); /* Centers the social links horizontally */
    width: 70%; /* Ensures the container width adapts to the screen */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#social-icons {
    display: flex;
    flex-direction: row; /* Stack icons horizontally */
    gap: 1.5vw; /* Gap between icons */
}

#social-links a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeInOnce 2s ease-in-out;
}

#social-links i {
    font-size: 3vw; /* Increased icon size for better visibility */
    color: #fff;
    transition: transform 0.3s ease, color 0.3s ease;
}

#social-links i:hover {
    color: #cacaca;
}

#social-links button {
    padding: 0.75em 1.5em; /* Adjusted padding for better button size */
    font-size: 1.25vw; /* Increased font size for readability */
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff; /* Thin white border */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: fadeInOnce 2s ease-in-out;
}

#social-links button:hover {
    background-color: #63cf59;
}

body.night #social-links button:hover {
    background-color: #f1c40f !important;
}

/* Adjustments for different screen sizes */
@media (max-width: 1024px) {
    #social-links {
        flex-direction: column;
        align-items: center; /* Center items horizontally */
        gap: 5em; /* Reduced gap for smaller screens */
        margin-top: 8em; /* Adjusted top margin for smaller screens */
        width: 100%; /* Full width for smaller screens */
    }

    #social-icons {
        flex-direction: column; 
    }

    #social-links i {
        font-size: 2em; /* Adjusted icon size for smaller screens */
    }

    #social-links button {
        padding: 0.5em 0.5em; /* Adjusted padding for smaller screens */
        font-size: 1em; /* Adjusted font size for readability */
    }
}

/* General styles for hero h1 */
#hero h1 {
    transition: 0.5s background ease-in-out;
    margin: 0;
    font-size: 9vw;
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-bottom: 1vh;
    background-image: url("../Assets/images/wave24.svg");
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: fadeIn 2s ease-in-out, moveBackground 25s ease-in-out infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Starts with a slight downward shift */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Ends at the original position */
    }
}

@keyframes moveBackground {
    0% {
        background-position: 50% 50%;
        background-size: cover;
    }
    25% {
        background-position: 75% 75%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 75% 75%;
        }
    100% {
        background-position: 50% 50%;
    }
}

body.night #hero h1 {
    transition: background 0.5s ease-in-out; /* Smoother transition */
    background-image: url("../Assets/images/wavenight3.svg") !important;
}

#hero p {
    font-size: 2.5vw;
    position: relative;
    z-index: 1;
    animation: fadeInUp 1.5s ease-in-out;
    display: inline-block;
    transition: opacity 0.5s ease-in-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#hero h1::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.4vh; /* Adjust thickness as needed */
    display: block;
    margin-top: 0.8vh; /* Adjust distance from text as needed */
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 10px; /* Rounded edges */
    transition: width 2.5s ease;
    -webkit-transition: width 2.5s ease;
}

#hero h1.animate-underline::after {
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
    transition: width 2.5s ease;
    -webkit-transition: width 2.5s ease;
}

@media (min-width: 1024px) {
    #hero h1.animate-underline::after {
        width: 90%;
    }
}

@media (max-width: 1024px) {
    #hero h1.animate-underline::after {
        width: 30%;
    }
}

@media (max-width: 767px) {
    #hero {
        flex-direction: column;
        justify-content: flex-start; /* Aligns content towards the top */
        align-items: center; /* Centers content horizontally */
        padding-top: 5em; /* Reduced padding to bring elements closer */
        height: 100vh;
    }

    #hero h1 {
        font-size: 6em; /* Adjusted font size for better fit */
        margin: 0; /* Removes any additional margin */
        text-align: center; /* Ensures the text is centered */
    }

    #hero p {        
        padding-top: 0.5em; /* Reduced padding to bring elements closer */
        font-size: 1.5em; /* Adjusted font size for mobile readability */
        margin-bottom: 1em; /* Reduced space below text */
    }

    body.night #hero h1 {
        font-size: 6em; /* Adjusted font size for better mobile fit */
        background-size: cover; /* Ensure the background image covers the entire element */
        background-position: center; /* Center the background image */
    }

    #hero h1.animate-underline::after {
        width: 75%;
    }

    @keyframes moveBackgroundMobile {
        0% {
            background-position: 50% 50%;
            background-size: cover;
        }
        25% {
            background-position: 100% 100%;
        }
        50% {
            background-position: 200% 200%;
        }
        75% {
            background-position: 100% 100%;
        }
        100% {
            background-position: 50% 50%;
        }
    }

    #hero {
        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */
        background-attachment: scroll; /* Remove fixed background on mobile */
    }

    body.night #hero {
        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */
        background-attachment: scroll; /* Remove fixed background on mobile */
    }

    body {
        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */

    }

    body.night {
        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */
    }

    #social-links {
        margin-top: auto; /* Positions social links at the bottom */
        margin-bottom: 1.5em; /* Adds slight bottom margin */
    }
}

/* Add this style for Edge browser compatibility */
@supports (-ms-ime-align: auto) {
    #hero h1 {
        background: none; /* Fallback for Edge */
        color: #fff; /* Fallback text color */
    }

    #hero h1::after {
        background-color: #cacaca; /* Fallback underline color */
    }
}
/* Hero/First Section DONE*/

/* CSS for the logo carousel */
.logo-carousel {
    position: relative;
    overflow: hidden;
    height: 100px;
    background: rgba(255, 255, 255, 0.5);
}

body.night .logo-carousel {
        background-color: rgba(26, 37, 47, 0.5) !important;
}

.logo-carousel .inner {
    position: relative;
    height: 100%;
}

.logo-carousel img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
    height: auto; /* Maintain aspect ratio */
    margin: 0 30px; /* Adjust horizontal margins for more space */
    transition: opacity 0.5s ease-in-out;
}

.fade-left, .fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 1;
    pointer-events: none;
}

.fade-right {
    right: 0;
    background: linear-gradient(to left, #f4f4f9, rgba(244, 244, 249, 0));
}

.fade-left {
    left: 0;
    background: linear-gradient(to right, rgba(244, 244, 249, 1), rgba(244, 244, 249, 0));
}

/* Update Logo Carousel for night theme */
.night .fade-right {
    right: 0;
    background: linear-gradient(to left, #1a252f, rgb(51, 51, 51,0)) !important;
}

.night .fade-left {
    left: 0;
    background: linear-gradient(to right,  #1a252f, rgba(30, 30, 30, 0)) !important;
}

/* Logo Carousel DONE */

/* Header CSS */
/* General Styles for Navigation */
header {
    color: #fff;
    padding: 2vh 0;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 2;
    transition: background 0.3s;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    animation: fadeInOnce 2s ease-in-out forwards;
}

header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1em;
}

header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

header nav ul li {
    margin: 0 2vh;
}

header nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.25vw;
    position: relative;
    padding: 1vh 0;
    transition: color 0.3s;
    outline: none;
}

header nav ul li a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0.3vh;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

header nav ul li a:hover::before {
    visibility: visible;
    width: 100%;
}

header nav ul li a:hover {
    color: #fff;
    text-decoration: none;
}

/* Night theme specific styles */
body.night header nav ul li a {
    color: #fff !important;
}

body.night header nav ul li a:hover {
    color: #f1c40f !important;
}

body.night header nav ul li a::before {
    background-color: #f1c40f !important;
}

button.theme-toggle {
    color: #fff;
    font-size: 50%;
    cursor: pointer;
    transition: transform 0.2s;
    height: 2vh;
    width: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    background: none;
    border: none;
    padding: 0.1vw;
}

button.theme-toggle:focus {
    outline: none;
    box-shadow: none;
}

#theme-toggle {
    font-size: 2vw;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

body.night button.theme-toggle {
    color: #f1c40f !important;
}

#theme-toggle.rotate {
    transition: transform 0.3s ease;
}

/* Hamburger menu styles */
.hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1000;
    outline: none; /* Remove the default outline */
}

.hamburger:focus {
    outline: none; /* Ensure no outline on focus for all browsers */
}

.hamburger:focus span {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); /* Adds a subtle glow effect */
}

.hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: all 0.3s ease;
}

/* Mobile Styles */
@media (max-width: 768px) {
    header {
        padding: 0;
        height: 60px; /* Fixed height for the header on mobile */
        position: fixed; /* Fix the header to the top */
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000; /* Ensure header is above other content */
    }

    header nav {
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 1em;
    }

    .hamburger {
        display: block;
        position: relative; /* Changed from absolute */
        z-index: 1001; /* Ensure it's above the menu */
    }

    button.theme-toggle {
        position: relative; /* Changed from absolute */
        z-index: 1001; /* Ensure it's above the menu */
    }

    #theme-toggle {
        margin: 4vw;
        font-size: 7vw;
    }

    header nav ul {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        background-size: cover;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23387c30' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%233a8133' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%233d8735' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%233f8c38' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%2342923A' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23459a3d' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2349a240' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%234caa42' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2350b245' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2354B949' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;  
    background-repeat: no-repeat; /* Ensuring the background doesn't repeat */backdrop-filter: blur(15px); /* Chrome, Edge, and Safari */
        -webkit-backdrop-filter: blur(15px); /* For Safari on iOS */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 0;
        transition: left 0.3s ease;
        overflow-y: auto;
        transition: background 0.5s ease-in-out;
        will-change: transform, backdrop-filter; /* Forces hardware acceleration */
    }

    .night header nav ul {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%232e4154' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23304458' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2333485c' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23354b60' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23374E64' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%233a5269' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%233d566f' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23405b74' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2346637F' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2346637F' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");

        height: 100vh;
        background-size: cover; /* Ensures the background covers the entire viewport */
        background-position: center center; /* Center the background image */
        background-attachment: scroll; /* Remove fixed background on mobile */    }
    
    .header::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
    }

    header nav ul.open {
        left: 0;
    }

    header nav ul li {
        margin: 1em 0;
    }

    header nav ul li a {
        font-size: 1.75em;
    }
}


/* Hamburger animation */
.hamburger.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

/* Night theme specific styles */
body.night .hamburger span {
    background-color: #f1c40f;
}

/* Fade-in animation */
@keyframes fadeInOnce {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Experience Section */
#experience {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* General Experience Item Styles */
.experience-item-honda,
.experience-item-hormann,
.experience-item-yr,
.experience-item-gr,
.experience-item-ff,
.experience-item-riipen {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 15px;
    background-color: transparent;
    backdrop-filter: blur(7px);
    max-width: 70rem;
    width: 80%;
    height: 100%;
    transition: background-image 0.5s ease, backdrop-filter 0.5s ease;
}

/* Company Logo Styles */
.logo-container,
.company-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    margin-right: 1.5rem;
    overflow: visible;
    cursor: pointer;
}

.company-logo-container {
    background-color: #ffffff;
}

.company-logo {
    width: 100%;
    height: auto;
}

/* Experience Content Styles */
.experience-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
}

.header-left {
    text-align: left;
    padding-left: 2%;
}

.header-right {
    text-align: right;
    padding-right: 2%;
}

.experience-body {
    display: flex;
    justify-content: space-between;
}

.experience-details,
.key-achievements {
    flex: 1;
    padding: 0 20px;
}

.experience-details h4,
.key-achievements h4 {
    text-align: center;
    margin-bottom: 0.5rem;
}

/* Background Gradients */
.experience-item-honda { background-image: linear-gradient(90deg, rgba(255, 0, 0, 0.2) 10%, rgba(255, 255, 255, 0.1) 30%)!important; }
.experience-item-hormann { background-image: linear-gradient(90deg, rgba(197, 154, 0, 0.2) 10%, rgba(255, 255, 255, 0.1) 30%)!important; }
.experience-item-gr { background-image: linear-gradient(90deg, rgba(43, 197, 0, 0.2) 10%, rgba(255, 255, 255, 0.1) 30%)!important; }
.experience-item-ff { background-image: linear-gradient(90deg, rgba(0, 0, 255, 0.2) 10%, rgba(255, 255, 255, 0.1) 30%)!important; }
.experience-item-yr { background-image: linear-gradient(90deg, rgba(0, 105, 197, 0.2) 10%, rgba(255, 255, 255, 0.1) 30%)!important; }
.experience-item-riipen { background-image: linear-gradient(90deg, rgba(255, 132, 0, 0.2) 10%, rgba(255, 255, 255, 0.1) 30%)!important; }

/* Night Theme Styles */
.night .experience-item-honda { background-image: linear-gradient(90deg, rgba(189, 96, 3, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }
.night .experience-item-hormann { background-image: linear-gradient(90deg, rgba(139, 105, 0, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%)!important; }
.night .experience-item-gr { background-image: linear-gradient(90deg, rgba(0, 100, 0, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%)!important; }
.night .experience-item-ff { background-image: linear-gradient(90deg, rgba(0, 0, 139, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%)!important; }
.night .experience-item-yr { background-image: linear-gradient(90deg, rgba(0, 0, 100, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%)!important; }
.night .experience-item-riipen { background-image: linear-gradient(90deg, rgba(152, 73, 0, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%)!important; }

.night .experience-details,
.night .job-title,
.night .job-duration,
.night .job-location,
.night .job-responsibilities,
.night .key-achievements,
.night .header-left {
    color: #f4f4f4 !important;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .experience-item-honda,
    .experience-item-hormann,
    .experience-item-gr,
    .experience-item-ff,
    .experience-item-yr,
    .experience-item-riipen,
    .night .experience-item-honda,
    .night .experience-item-hormann,
    .night .experience-item-gr,
    .night .experience-item-ff,
    .night .experience-item-yr,
    .night .experience-item-riipen {
        flex-direction: column;
        align-items: center;
        width: 90%;
        background-image: linear-gradient(180deg, var(--start-color) 10%, rgba(255, 255, 255, 0.1) 30%) !important;
    }

    .experience-item-honda { --start-color: rgba(255, 0, 0, 0.2); }
    .experience-item-hormann { --start-color: rgba(197, 154, 0, 0.2); }
    .experience-item-gr { --start-color: rgba(43, 197, 0, 0.2); }
    .experience-item-ff { --start-color: rgba(0, 0, 255, 0.2); }
    .experience-item-yr { --start-color: rgba(0, 105, 197, 0.2); }
    .experience-item-riipen { --start-color: rgba(197, 122, 0, 0.2);}

    /* Night Theme Styles */
.night .experience-item-honda { background-image: linear-gradient(180deg, rgba(139, 0, 0, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }
.night .experience-item-hormann { background-image: linear-gradient(180deg, rgba(139, 105, 0, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }
.night .experience-item-gr { background-image: linear-gradient(180deg, rgba(0, 100, 0, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }
.night .experience-item-ff { background-image: linear-gradient(180deg, rgba(0, 0, 139, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }
.night .experience-item-yr { background-image: linear-gradient(180deg, rgba(0, 0, 100, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }
.night .experience-item-riipen { background-image: linear-gradient(180deg, rgba(202, 113, 4, 0.3) 10%, rgba(26, 37, 47, 0.3) 30%) !important; }

.logo-container,
    .company-logo-container {
        width: 80%;
        margin-bottom: 1rem;
        margin-right: 0;
    }

    .experience-content {
        width: 100%;
    }

    .experience-header {
        flex-direction: column;
        align-items: center;
    }

    .header-left,
    .header-right {
        text-align: center;
    }

    .experience-body {
        flex-direction: column;
        align-items: center;
    }

    .experience-details,
    .key-achievements {
        padding: 0;
        text-align: center;
        border: none;
    }

    .experience-details ul,
    .key-achievements ul {
        text-align: left;
    }

    .experience-details {
        border-bottom: 2px solid #ccc;
        margin-bottom: 1rem;
    }
}

/* Desktop Styles */
@media (min-width: 769px) {
    .experience-details {
        border-right: 2px solid #b7b7b7;
    }
}
/* Section Styles */

/* About Section */

#about {
    padding: 6em 1em;
    position: relative;
    overflow: hidden;
}

.about-content {
    max-width: 80%;
    width: 70%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.95);
    padding: 3em;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    gap: 3em;
    position: relative;
    z-index: 1;
    transition: transform 0.3s, box-shadow 0.3s;
}

body.night .about-content {
    background-color: rgba(26, 37, 47, 0.5) !important;
    box-shadow: 0 10px 30px rgba(0,0,0,0.3);
}

.profile-pic-container {
    position: relative;
    width: 200px;
    height: 200px;
}

.profile-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #53B944;
    transition: transform 0.3s, box-shadow 0.3s;
}

body.night .profile-pic {
    border-color: #f1c40f !important;
}

body.night .profile-pic:hover {
    box-shadow: 0 0 20px rgba(241, 196, 15, 0.5) !important;
}

.about-text, .dynamic-text {
    flex: 1;
    text-align: left;
}

body.night .about-text, body.night .dynamic-text {
    color: #f4f4f4 !important;
}

p, .dynamic-text {
    line-height: 1.6;
    margin-bottom: 1em;
}

.highlight {
    background: linear-gradient(120deg, rgba(84, 185, 73, 0.2) 0%, rgba(84, 185, 73, 0.2) 100%);
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-weight: 600;
}

body.night .highlight {
    background: linear-gradient(120deg, rgba(241, 196, 15, 0.2) 0%, rgba(241, 196, 15, 0.2) 100%) !important;
}

.interests {
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
}

.interest-item {
    text-align: center;
    transition: transform 0.3s;
}

.interest-item i {
    font-size: 2em;
    margin-bottom: 0.5em;
    color: #54B949;
}

body.night .interest-item i {
    color: #f1c40f !important; 
}

.tools-languages {
    margin-top: 2em;
}

.tools-languages p {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em;
}

.icons-container {
    display: flex;
    justify-content: space-around;
    align-items: center; /* Centers items vertically within the container */
    flex-wrap: wrap;
    height: 100%; /* Ensure the container takes up the full height of its parent */
}

.tool-icon {
    display: flex;
    justify-content: center;
    align-items: center; /* Centers the icon vertically and horizontally within its container */
    width: 37px;
    height: fit-content; /* Set a fixed height to help with vertical centering */
    margin: 0.7vw;
    transition: transform 0.5s;
}

.tool-icon:hover {
    transform: scale(1.5);
}

.social-links {
    display: flex; /* Use flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    margin-top: 2em; /* Adjust as needed */
    text-align: center; /* Ensure text inside is centered */
}

.social-links a {
    margin: 0 15px;
    font-size: 1.8em;
    color: #333;
    transition: color 0.3s, transform 0.3s;
}

.social-links a:hover {
    color: #54B949;
    transform: scale(1.2);
}

body.night .social-links a {
    color: #e0e0e0 !important;
}

body.night .social-links a:hover {
    color: #f1c40f !important;
}

.bg-shapes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.5;
    pointer-events: none;
}

body.night .shape {
    background-color: #f1c40f !important;
}

@media (max-width: 768px) {

    .about-content {
        flex-direction: column;
        text-align: center;
        max-width: 90%; /* Increase width for mobile view */
        width: 90%;
        padding: 1.5em;
    }

    .interest-item {
        flex-basis: 30%; /* Allows 3 items in a row */
        font-size: 0.7em; /* Slightly smaller font for text */
    }

    .interests {
        justify-content: space-around;
    }

    .profile-pic-container {
        margin-bottom: 2em;
    }

    .icons-container {
        justify-content: center;
    }

    .social-links {
        display: flex;
        justify-content: center;
    }
}

/* About DONE*/

/* Portfolio/Projects START */
#portfolio {
    padding: 2rem 1rem;
    text-align: center;
    border-radius: 8px;
    max-width: 80%;
    margin: 2rem auto;
}

#portfolio h2 {
    margin-bottom: 1rem;
    font-size: 2em;
    color: #333;
}

body.night #portfolio h2 {
    color: #f4f4f9 !important;
}

#portfolio a {
    position: relative;
    text-decoration: none;
    z-index: 1;
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1.5rem;
}

.portfolio-item {
    margin: 1em;
    padding: 1.5em;
    border: 1px solid #ddd;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    z-index: 0; /* Ensure the portfolio-item is below the a tag */
}

.portfolio-item h3 {
    text-align: center;
}

body.night .portfolio-item {
    background-color: rgba(26, 37, 47, 0.5) !important;
    border: 1px solid #2c3e50;
}

.portfolio-link {
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none; /* Ensure no text decoration on the link */
}

.portfolio-link h3 {
    font-size: 1.5em;
    color: #53B944;
}

body.night .portfolio-link h3 {
    color: #f1c40f !important;
}

.portfolio-link p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 1rem;
}

body.night .portfolio-link p {
    color: #f4f4f9 !important;
}

.portfolio-link .tech-stack {
    margin-top: 1rem;
}

.portfolio-link .tech-stack h4 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 0.5rem;
}

body.night .portfolio-link .tech-stack h4 {
    color: #f4f4f9 !important;
}

.portfolio-link .tech-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.portfolio-link .tech-icons img {
    width: 50px;
    height: auto;
    margin: 0.5rem;
    transition: transform 0.3s;
}

.portfolio-link .tech-icons img:hover {
    transform: scale(1.1);
}

.github-link {
    color: #53B944;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

body.night .github-link {
    color: #f4f4f9 !important;
}

.github-link i {
    margin-right: 0.5rem;
}

.portfolio-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.portfolio-item:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.portfolio-item .portfolio-link {
    flex-grow: 1;
}

/* Responsive design */
@media (max-width: 768px) {
    .portfolio-link .tech-icons img {
        width: 40px;
        margin: 0.3rem;
    }

    .portfolio-item {
        margin: 1em auto;
        width: 90%;
    }

    #portfolio {
        margin: 0 auto;
        padding: 2rem 0;
        max-width: 100%;
    }

    .portfolio-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
/* Portfolio/Projects DONE */

/* Skills Section */
#skills {
    padding: 50px 0;
    text-align: center;
}

#skills h2 {
    margin-bottom: 1em;
}

.skills-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.skill-category {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 300px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.skill-category:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.skill-category h3 {
    font-size: 1.75em;
    margin-bottom: 15px;
    color:#53B944;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skill-category h3 i {
    margin-right: 10px;
    color: #53B944;
}

.skill-category ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.skill-category ul li {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #555;
    position: relative;
    padding-left: 25px;
}

.skill-category ul li::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    left: 0;
    color:#53B944;
}

/* Night theme styles for Skills Section */
body.night #skills {
    color: #f4f4f4 !important;
}

body.night .skill-category {
    background-color: rgba(26, 37, 47, 0.5) !important;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

body.night .skill-category h3 {
    color: #f4f4f4 !important;
}

body.night .skill-category h3 i {
    color: #f1c40f !important;
}

body.night .skill-category ul li {
    color: #ccc !important; 
}

body.night .skill-category ul li::before {
    color: #f1c40f !important;
}
/* Skill Section DONE*/

footer {
    color: #000000;
    text-align: center;
    padding: 1em 0;
    position: relative;
    width: 100%;
    text-decoration: none;
}

footer a {
    color: inherit; /* Inherit the color from the parent (footer) */
    text-decoration: none; /* Remove underline for links */
}

footer a:hover, footer a:active, footer a:visited {
    color: inherit; /* Ensure no color change on hover, active, or visited states */
    text-decoration: none; /* Prevent underline on hover */
}

body.night footer {
    color: #fff;
}

@media (max-width: 769px) {
    .skill-category {
        width: 90%;
    }
}

/* Parallax Effect for About Section */
@media (min-width: 768px) {
    .projects {
        justify-content: space-around;
    }
}

@media (min-width: 1024px) { 
    .project-item {
        width: 45%;
    }
}

@media (min-width: 1440px) {
    .project-item {
        width: 30%;
    }
}

/* Certifications Section */

#certifications {
    padding: 50px 20px;
    transition: background-color 0.3s ease;
}

.certifications-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.certification-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures items are evenly spaced */
    height: 100%; /* Makes sure the certification items are consistent in height */
}

.night .certification-item {
    background-color: rgba(26, 37, 47, 0.5) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.certification-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.night .certification-item:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15) !important;
}

.certification-logo {
    background-color: #f1f3f5;
    text-align: center;
    transition: background-color 0.3s ease;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.night .certification-logo {
    background-color: rgba(241, 243, 245, 0.1) !important;
}

.certification-logo img {
    max-width: 100%;
    max-height: 80px; /* Ensure images have consistent max height */
    object-fit: contain; /* Ensures the image fits within the constraints */
}

.certification-details {
    padding: 20px;
    flex-grow: 1; /* Makes sure the content grows to fill available space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Spaces out content evenly, ensuring the button stays at the bottom */
}

.certification-details h3 {
    margin: 0 0 10px;
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    transition: color 0.3s ease;
}

.night .certification-details h3 {
    color: #f8f9fa !important;
}

.certification-details p {
    margin: 0 0 5px;
    color: #666;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.night .certification-details p {
    color: #bbb !important;
}

.certification-date {
    font-style: italic;
    color: #888;
    font-size: 0.8rem;
    transition: color 0.3s ease;
}

.night .certification-date {
    color: #999 !important;
}

.certification-link {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 15px;
    background-color: #53B944;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
    align-self: center; /* Centers the button within the container */
}

.certification-link:hover {
    background-color: #45a037;
    text-decoration: none; /* Removes the underline on hover */
}

.night .certification-link {
    background-color: #f1c40f !important;
    color: #1a252f !important;
}

.night .certification-link:hover {
    background-color: #d4ac0d !important;
    text-decoration: none; /* Removes the underline on hover */
}

@media (max-width: 768px) {
    .certifications-container {
        grid-template-columns: 1fr;
        width: 90%;
    }
}

/* Education Section START */
#education {
    position: relative;
    padding: 50px 0;
    text-align: center;
}

.education-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education-item {
    width: 60%;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    position: relative;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Night Mode for education-item */
.night .education-item {
    background-color: rgba(26, 37, 47, 0.5) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Slightly more intense shadow */
}

.university-logo {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    flex-shrink: 0;
}

.education-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: left;
}

.education-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.info-left {
    text-align: left;
}

.info-right {
    text-align: right;
    flex-shrink: 0;
}

/* Night Mode for text colors */
.night .education-details h3,
.night .degree,
.night .duration,
.night .location,
.night .achievements li {
    color: #f4f4f4 !important; /* Light text color */
}

.education-details h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
    color: #333;
}

/* Night Mode overrides for h3 */
.night .education-details h3 {
    color: #f4f4f4 !important;
}

.degree,
.duration,
.location {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

/* Night Mode for degree, duration, and location */
.night .degree,
.night .duration,
.night .location {
    color: #cccccc !important; /* Lighter text for additional details */
}

.achievements {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
}

.achievements li {
    margin-bottom: 5px;
    color: #444;
}

/* Night Mode for achievements */
.night .achievements li {
    color: #bbbbbb !important; /* Lighter text for achievements */
}

/* Mobile Styles */
@media (max-width: 768px) {
    .education-item {
        flex-direction: column;
        align-items: center; /* Center all content on mobile */
        width: 80%;
    }

    .university-logo {
        margin-bottom: 5px;
        margin-right: 0;
        align-self: center; /* Ensure logo is centered */
    }

    .info-row {
        flex-direction: column;
        align-items: center; /* Center the text on mobile */
        text-align: center; /* Center the text */
    }

    .info-right {
        text-align: center;
        margin-top: 5px;
    }

    .info-left {
        text-align: center;
        margin-top: 5px;
    }


    /* Ensure achievements remain left-aligned */
    .achievements {
        text-align: left;
        align-self: flex-start; /* Ensure achievements are left-aligned */
        margin-left: 0; /* Remove the additional left margin */
    }
}

/* Night Mode for #education Section */
.night #education {
    color: #f4f4f4 !important; /* Light text for the section */
}


/* Contact Section */
#contact {
    padding: 2rem 1rem;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 2rem auto;
}

body.night #contact {
    background-color: rgba(26, 37, 47, 0.5) !important;
}

#contact h2 {
    margin-bottom: 1rem;
    font-size: 2em;
    color: #333;
}

.contact-info {
    font-size: 1.2em;
    color: #555;
    display: flex;
    flex-direction: column;
    align-items: center;
}

body.night .contact-info a {
    color:  #f9f9f9 !important;
}

.contact-info p {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
}

.contact-info i {
    margin-right: 0.5rem;
    font-size: 1.5em;
    color: #53B944;
}

.contact-info a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
}

body.night .contact-info i {
    color:  #f1c40f !important;
}

.contact-info a:hover {
    color: #333;
}

/* Contact Section DONE*/